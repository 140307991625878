export default [
  {
    title: 'Record Patrol',
    route: 'apps-patrols-record',
    icon: 'MapPinIcon',
    resource: 'patrols',
    action: 'read',
  },
  {
    title: 'Patrols',
    route: 'apps-patrols',
    icon: 'MapPinIcon',
    resource: 'patrols',
    action: 'read',
  },
]

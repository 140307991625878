export default [
  {
    header: 'Camera Console',
    icon: 'CameraIcon',
    children: [
      {
        title: 'Cameras List',
        route: 'apps-cameraconsole-list',
        icon: 'CameraIcon',
        resource: 'cameras',
        action: 'read',
      },
    ],
  },
  {
    header: 'System Management',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Cameras Management',
        route: 'apps-cameras-management-list',
        icon: 'CameraIcon',
        resource: 'camerasmanagement',
        action: 'read',
      },
      {
        title: 'Users Management',
        route: 'apps-users-list',
        icon: 'UserIcon',
        resource: 'users',
        action: 'read',
      },
      {
        title: 'Onboarding',
        route: 'apps-onboarding',
        icon: 'UserPlusIcon',
        resource: 'onboarding',
        action: 'read',
      },
      {
        title: 'Client Management',
        route: 'apps-clients-list',
        icon: 'UserIcon',
        resource: 'clients',
        action: 'read',
      },
      {
        title: 'Abilities Templates',
        route: 'apps-abilities-list',
        icon: 'UserIcon',
        resource: 'abilitiestemplates',
        action: 'read',
      },
    ],
  },
]

export default [
  {
    title: 'Alerts - Manage',
    route: 'apps-alerts',
    icon: 'AlertTriangleIcon',
    resource: 'alerts',
    action: 'create',
  },
  {
    title: 'Alerts',
    route: 'apps-alerts-view',
    icon: 'AlertTriangleIcon',
    resource: 'alerts',
    action: 'read',
  },
]

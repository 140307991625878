export default [
  {
    header: 'Documents',
    icon: 'FolderIcon',
    children: [
      {
        title: 'Post Orders',
        route: 'apps-postorders',
        icon: 'RadioIcon',
        resource: 'postorders',
        action: 'read',
      },
      {
        title: 'Corporate Policies',
        route: 'apps-corporatepolicy',
        icon: 'BookIcon',
        resource: 'corporatepolicy',
        action: 'read',
      },
    ],
  },
]
